<template>
  <div>
    <!-- Mobile layout -->
    <responsive-layout
      v-if="$vuetify.breakpoint.mobile"
      poster="https://vod.eventcdn.net/pandora/smc2021/Pandora_LobbyBg_mobile.jpg"
      #default="{ toggleOverlay }"
    >
      <v-sheet
        style="min-height: 100vh"
        class="d-flex flex-column justify-center transparent"
        light
      >
        <div class="main py-8 flex-fill">
          <v-container>
            <v-row
              justify="center"
              align="center"
              class="black pa-0"
              style="position: fixed; bottom: 0; width: 100%"
            >
              <v-col cols="12" class="text-center white--text">
                <h2 class="mb-5 mt-2 lobby-mobile-welcome-text">WELCOME</h2>
                <p class="lobby-mobile-text-content">
                  To get the best experience on this site, please access it in
                  horizontal view from a tablet, laptop or desktop (min. 720p).
                </p>
                <v-btn
                  color="pink"
                  class="ma-2 white--text mobile-navigation-button rounded-0"
                  @click="toggleOverlay()"
                >
                  <span
                    class="
                      mobile-navigation-button-text
                      black--text
                      text-center
                    "
                    >OPEN MENU</span
                  >
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-sheet>
    </responsive-layout>

    <!-- 16:9 layout for desktop devices -->
    <aspect-ratio-layout
      poster="https://vod.eventcdn.net/pandora/smc2021/backgrounds/Lobby_loop.jpg"
      :background-video-initial="backgroundVideoInitial"
      background-video-loop="https://vod.eventcdn.net/pandora/smc2021/BackgroundVideo/loop/lobby_loop.mp4"
      v-else
    >
      <template #default="{ replayBackgroundVideo, isLooping }">
        <!--<v-sheet
          class="d-flex flex-column justify-center transparent"
          style="height: 100%; width: 100%"
          light
        >-->
        <div class="main">
          <v-container>
            <v-row justify="center" align="center" class="mx-4 mb-8 mb-lg-12">
              <v-col
                class="countdown-container"
                cols="12"
                style="max-width: 580px"
              >
                <!-- Waiting -->
                <template v-if="event.state === 'WAITING'">
                  <div class="mx-auto">
                    <p class="black--text countdown-headline">
                      Day {{ event.day }} starts in:
                    </p>
                    <br />
                    <lh-countdown
                      :time="countdownTime"
                      value-class="countdown-value countdown-with-pink-text"
                      content-class="black--text mt-n10"
                      :hideCountDown="hideCountDown"
                      color="pink"
                      :labels="{
                        days: 'Days',
                        hours: 'Hours',
                        minutes: 'Minutes',
                        seconds: 'Seconds',
                      }"
                    ></lh-countdown>
                    <!-- <v-col cols="12" class="mt-5">
                    <v-btn color="black" class="play-btn-introduction">
                      <span class="white--text play-btn-text-introduction"
                        >SEE INTRODUCTION VIDEO</span
                      >
                    </v-btn>
                    <div
                      class="play-btn-white-rounded-background-introduction"
                      :disabled="isPlaying"
                      @click="
                        playIntroductionVideo();
                        toggleVideoOrCountDown();
                      "
                    >
                      <v-icon
                        class="play-btn-arrow-introduction"
                        dark
                        color="pink"
                      >
                        fas fa-play
                      </v-icon>
                    </div>
      
                  </v-col> -->
                  </div>
                </template>
                <!-- Live -->
                <template v-if="event.state === 'LIVE'">
                  <div class="mx-auto">
                    <h1 class="black--text mt-n6" style="text-transform: unset">
                      {{ currentSession.name }} <br />is live!
                    </h1>

                    <v-btn
                      tile
                      elevation="0"
                      :to="{ name: 'Session', params: currentSession.id }"
                      class="font-weight-bold black--text mt-8"
                      color="primary"
                      >JOIN NOW</v-btn
                    >
                  </div>
                </template>

                <!-- Break -->
                <template v-if="event.state === 'BREAK'">
                  <div class="mx-auto">
                    <h2 class="black--text" style="text-transform: unset">
                      We are on a break.
                    </h2>

                    <h3
                      class="black--text pt-8 mx-auto"
                      style="text-transform: unset"
                    >
                      Dare to have a look around <br />
                      and explore the site.
                    </h3>
                  </div>
                </template>
                <!-- OnDemand -->
                <template v-if="event.state === 'ONDEMAND'">
                  <div class="mx-auto mt-n4">
                    <h3 class="black--text" style="text-transform: unset">
                      Thank you for joining <br />
                      PANDORA SMC 2021
                    </h3>

                    <h3
                      class="black--text pt-8 mx-auto"
                      style="text-transform: unset"
                    >
                      Dare to have a look around <br />
                      the On Demand site.
                    </h3>
                  </div>
                </template>
              </v-col>
              <template v-if="isLooping">
                <v-col cols="12" class="mt-12 welcome-video-container">
                  <v-btn color="black" class="play-btn">
                    <span class="white--text play-btn-text">WELCOME</span>
                  </v-btn>
                  <div
                    class="play-btn-white-rounded-background"
                    :disabled="isPlaying"
                    @click="replayBackgroundVideo"
                  >
                    <v-icon class="play-btn-arrow" dark color="pink">
                      fas fa-play
                    </v-icon>
                  </div>
                </v-col>
              </template>
            </v-row>

            <v-row>
              <router-link
                class="theatre-container col-6"
                to="/theatre"
              ></router-link>
            </v-row>

            <v-row>
              <router-link
                class="lounge-container col-6"
                to="/lounge"
              ></router-link>
            </v-row>

            <v-row>
              <router-link
                class="meet-your-colleagues-container col-6"
                to="/meet-your-colleagues"
              ></router-link>
            </v-row>

            <v-row>
              <router-link
                class="information-container col-6"
                to="/information"
              ></router-link>
            </v-row>

            <v-row>
              <router-link
                class="tour-de-pandora-container col-6"
                to="/tour-de-pandora"
              ></router-link>
            </v-row>

            <!-- First hidden dialog/question box -->

            <AppHiddenQuestion
              top="64%"
              left="23%"
              question="What is the tagline to selling Pandora Me?"
              :options="[
                'Express Yourself',
                'For every story, for every me',
                'Endless combinations',
              ]"
              answer="For every story, for every me"
              :tracking-id="21"
            ></AppHiddenQuestion>

            <!-- Second hidden dialog/question box -->
            <AppHiddenQuestion
              top="71%"
              right="17.75%"
              question="Which behavior is NOT something we want our line leads to do when managing the line?"
              :options="[
                'Ask questions to shoppers while they wait',
                'Introduce the shopper to the Pandora Credit Card',
                'Avoid talking to shoppers while they are waiting in line',
              ]"
              answer="Avoid talking to shoppers while they are waiting in line"
              :tracking-id="22"
            ></AppHiddenQuestion>

            <!-- Third hidden dialog/question box -->

            <AppHiddenQuestion
              top="32%"
              right="32%"
              question="Which emotion was discussed that we want to create with our shoppers?"
              :options="['I am lucky.', 'I feel strong.', 'I belong.']"
              answer="I belong."
              :tracking-id="23"
            ></AppHiddenQuestion>
          </v-container>
        </div>
        <!--</v-sheet>-->
      </template>
    </aspect-ratio-layout>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import ResponsiveLayout from "../layouts/ResponsiveLayout.vue";
import AspectRatioLayout from "../layouts/16by9Layout.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ResponsiveLayout,
    AspectRatioLayout,
  },

  data() {
    return {
      credentials: {
        username: null,
        password: null,
      },
      error: false,
      time: "2021-09-14T15:30:00",
      testTime: "2020-09-14T15:30:00",
      form: {
        name: null,
        email: null,
        store_name: null,
        territory_manager: null,
        registration_type: null,
        livehouse_terms: null,
        //pandora_terms: null,
      },
      isPlaying: false,
      hideCountDown: false,
      dialog: false,
      dialogSecretSecond: false,
      dialogSecretThird: false,
      column: null,
      row: null,
      overlay: false,
    };
  },

  computed: {
    ...mapState(["event"]),
    ...mapGetters(["me", "currentSession", "countdownTime"]),

    backgroundVideoInitial() {
      if (this.event?.state !== "ONDEMAND") {
        return "https://vod.eventcdn.net/pandora/smc2021/BackgroundVideo/Lobby.mp4";
      }

      return "https://vod.eventcdn.net/pandora/smc2021/BackgroundVideo/Lobby_Ondemand.mp4";
    },
  },

  watch: {
    "$store.getters.isAuthenticated": async function (newValue, oldValue) {
      console.log("isAuthenticated change:", newValue, "oldValue:", oldValue);
      if (newValue) {
        await this.onLogin();
      }
    },
  },

  methods: {
    navigate() {
      const next = this.$route.query?.next;
      if (next) {
        this.$router.push(next);
      } else if (this.$store.getters.isAdmin) {
        this.$router.push({ name: "AdminHome" });
      } else {
        this.$router.push({ name: "Home" });
      }
    },
    async login() {
      this.error = false;
      try {
        await this.$store.dispatch("login", this.credentials);
      } catch (e) {
        this.error = true;
        console.debug("Login error:", e);
      }
    },
    async onLogin() {
      // Navigate
      this.navigate();
    },

    playIntroductionVideo() {
      const playArrow = document.getElementsByClassName(
        "play-btn-arrow-introduction"
      )[0];

      if (this.isPlaying == false && this.$session.get("firstTime", true)) {
        playArrow.classList.remove("fa-play");
        playArrow.classList.add("fa-pause");

        this.$refs.introductionVideo.play();
        this.isPlaying = true;
      } else {
        this.$refs.introductionVideo.pause();
        this.isPlaying = false;

        playArrow.classList.remove("fa-pause");

        playArrow.classList.add("fa-play");
      }
    },

    playWelcomeVideo() {
      const playArrow = document.getElementsByClassName("play-btn-arrow")[0];

      if (this.isPlaying == false) {
        playArrow.classList.remove("fa-play");
        playArrow.classList.add("fa-pause");

        this.$refs.welcomeVideo.play();
        this.isPlaying = true;
      } else {
        this.$refs.welcomeVideo.pause();
        this.isPlaying = false;

        playArrow.classList.remove("fa-pause");

        playArrow.classList.add("fa-play");
      }
    },

    toggleVideoOrCountDown() {
      var currentDate = new Date();
      var currentDateAsIso = currentDate.toISOString();

      if (this.testTime <= currentDateAsIso) {
        this.hideCountDown = true;
      }
    },
  },

  mounted() {
    // this.$session.set("firstTime", true);
    console.log(this.$vuetify.breakpoint);
  },
};
</script>

<style>
.main {
  backface-visibility: hidden;
  /*height: 1080px !important;
  width: 1920px !important; */
  position: relative;

  z-index: 1;
}

@media screen and (max-width: 1365px) {
  .main {
    background-position: left;
  }
}

.v-application.livehouse-app .countdown-value {
  font-size: 5rem !important;
  color: var(--v-primary-base) !important;
  line-height: 5rem !important;
  font-weight: 700 !important;
}

@media screen and (max-width: 992px) {
  .v-application.livehouse-app .countdown-value {
    font-size: 3rem !important;
    line-height: 3.5rem !important;
  }
}

.countdown-with-pink-text {
  color: var(--pink) !important;
  font: var(--unnamed-font-style-normal) normal bold 80px/60px
    var(--unnamed-font-family-pan-text);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal bold 80px/60px Pan Text;
}

.countdown-headline {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) 22px/32px
    var(--unnamed-font-family-pan-text);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: center;
  font: normal normal normal 22px/32px Pan Text;
}

.unit {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-18) /
    var(--unnamed-line-spacing-22) var(--unnamed-font-family-pan-text);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--darker-grey);
  text-align: center;
  font: normal normal normal 18px/22px Pan Text;
}

.countdown-container {
  margin-top: 9.5rem;
}
</style>
